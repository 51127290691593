import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import Img1 from "./assets/home-page-1.png";
import Img2 from "./assets/Home-page-2.png";
import Img3 from "./assets/Home-page-3.png";

import styled from "styled-components";

import { Card, Col, Row } from "antd";

const { Meta } = Card;

function Features() {
  return (
    <section className="section" style={{ backgroundColor: "#ffcb1f" }}>
      <h1 className="header"> WHY ENERGEN WORKS? </h1>

      <div>
        <Row gutter={100} className="cardRow">
          <div className="colCard">
            <Col
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
              md={{ span: 16 }}
              sm={{ span: 16 }}
              xs={{ span: 16 }}
              className="card"
            >
              <StyledCard
                bordered={false}
                cover={
                  <img
                    alt="example"
                    src={Img1}
                    style={{
                      maxHeight: "100px",
                      maxWidth: "100px",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                  />
                }
              >
                <Meta className="font24" title="MULTIPLE OFFICES" />
                <p
                  style={{
                    paddingTop: "15px",
                    margin: 0
                  }}
                  className="color-333132 font20"
                >
                  City hopping on work? Not a problem. As a member you get
                  exclusive access to all our spaces, at a minimal or no
                  additional cost.
                </p>
              </StyledCard>
            </Col>
          </div>
          <div className="colCard">
            <Col
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
              md={{ span: 16 }}
              sm={{ span: 16 }}
              xs={{ span: 16 }}
              className="card"
            >
              <StyledCard
                bordered={false}
                cover={
                  <img
                    alt="example"
                    src={Img2}
                    style={{
                      maxHeight: "100px",
                      maxWidth: "100px",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                  />
                }
              >
                <Meta className="font24" title="CUSTOMISABLE SPACES" />
                <p
                  style={{
                    paddingTop: "15px",
                    margin: 0
                  }}
                  className="color-333132 font20"
                >
                  Get exactly what you want and how you want it! Create your
                  custom work space, to make it completely 'You' in every way.
                </p>
              </StyledCard>
            </Col>
          </div>
          <div className="colCard">
            <Col
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
              md={{ span: 16 }}
              sm={{ span: 16 }}
              xs={{ span: 16 }}
              className="card"
            >
              <StyledCard
                bordered={false}
                cover={
                  <img
                    alt="example"
                    src={Img3}
                    style={{
                      maxHeight: "100px",
                      maxWidth: "100px",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                  />
                }
              >
                <Meta className="font24" title="TECH SUPPORT" />
                <p
                  style={{
                    paddingTop: "15px",
                    margin: 0
                  }}
                  className="color-333132 font20"
                >
                  Round the clock tech support allows you to enjoy a seamless
                  work experience minus the glitches and downtime.
                </p>
              </StyledCard>
            </Col>
          </div>
        </Row>
      </div>
    </section>
  );
}

const StyledCard = styled(Card)`
  .ant-card-body {
    padding-bottom: 0;
  }
`;

export default Features;
