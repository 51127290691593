import React from "react";
import "antd/dist/antd.css";
import styled from "styled-components";

import Facebook from "../assets/facebook.png";
import Instagram from "../assets/instagram.png";
import { Col } from "antd";
import { Link } from "react-router-dom";

function MobileFooterElement() {
  return (
    <Div id="MobileFooterElement">
      <Col
        lg={{ span: 2, offset: 4 }}
        xl={{ span: 2, offset: 4 }}
        xxl={{ span: 2, offset: 4 }}
        md={{ span: 7, offset: 5 }}
        sm={{ span: 7, offset: 5 }}
        xs={{ span: 7, offset: 5 }}
        style={{ borderRight: "1px solid #f4f4f4" }}
      >
        <Link to="/locations" style={{ color: "#f4f4f4" }}>
          Bengaluru
        </Link>
      </Col>

      <Col
        lg={{ span: 2, offset: 0 }}
        xl={{ span: 2, offset: 0 }}
        xxl={{ span: 2, offset: 0 }}
        md={{ span: 7, offset: 0 }}
        sm={{ span: 7, offset: 0 }}
        xs={{ span: 7, offset: 0 }}
      >
        <Link to="/locations" style={{ color: "#f4f4f4" }}>
          Gurugram
        </Link>
      </Col>
      <Col
        lg={{ span: 4, offset: 0 }}
        xl={{ span: 4, offset: 0 }}
        xxl={{ span: 4, offset: 0 }}
        md={{ span: 14, offset: 5 }}
        sm={{ span: 14, offset: 5 }}
        xs={{ span: 14, offset: 5 }}
      >
        <a
          href="mailto:info@energenworks.com?subject=Need%20more%20info%20on%20EnergenWorks.com"
          target="_top"
          style={{ color: "#f4f4f4" }}
        >
          info@energenworks.com
        </a>
      </Col>
      <Col
        lg={{ span: 4, offset: 0 }}
        xl={{ span: 4, offset: 0 }}
        xxl={{ span: 4, offset: 0 }}
        md={{ span: 22, offset: 1 }}
        sm={{ span: 22, offset: 1 }}
        xs={{ span: 22, offset: 1 }}
      >
        <Col span={12} style={{ borderRight: "1px solid #f4f4f4" }}>
          <a href="tel:+916383912840" style={{ color: "#f4f4f4" }}>
            +916383912840
          </a>
        </Col>

        <Col span={12}>
          <a href="tel:+916383912843" style={{ color: "#f4f4f4" }}>
            +916383912843
          </a>
        </Col>
      </Col>
      <Col
        lg={{ span: 2, offset: 0 }}
        xl={{ span: 2, offset: 0 }}
        xxl={{ span: 2, offset: 0 }}
        md={{ span: 16, offset: 4 }}
        sm={{ span: 16, offset: 4 }}
        xs={{ span: 16, offset: 4 }}
      >
        <a
          href="https://www.facebook.com/energenworks/"
          target="_blank"
          style={{ color: "#f4f4f4", marginRight: 10 }}
        >
          <img
            src={Facebook}
            style={{ maxHeight: "20px", paddingBottom: "2px" }}
            alt="Facebook"
          />
        </a>

        <a
          href="https://www.instagram.com"
          target="_blank"
          style={{ color: "#f4f4f4" }}
        >
          <img
            src={Instagram}
            style={{ maxHeight: "20px", paddingBottom: "2px" }}
            alt="Instagram"
          />
        </a>
      </Col>
    </Div>
  );
}

function FooterElement() {
  return (
    <Div id="FooterElement">
      <div>
        <Link to="/locations" style={{ color: "#f4f4f4" }}>
          Bengaluru
        </Link>
        &emsp;|&emsp;
        <Link to="/locations" style={{ color: "#f4f4f4" }}>
          Gurugram
        </Link>
        &emsp;| &emsp;
        <a
          href="mailto:info@energenworks.com?subject=Need%20more%20info%20on%20EnergenWorks.com"
          target="_top"
          style={{ color: "#f4f4f4" }}
        >
          info@energenworks.com
        </a>
        &emsp;| &emsp;
        <a href="tel:+916383912840" style={{ color: "#f4f4f4" }}>
          +916383912840
        </a>
        &emsp;|&emsp;
        <a href="tel:+916383912843" style={{ color: "#f4f4f4" }}>
          +916383912843
        </a>
        &emsp;|&emsp;
        <a
          href="https://www.facebook.com/energenworks/"
          target="_blank"
          style={{ color: "#f4f4f4" }}
        >
          <img
            src={Facebook}
            style={{ maxHeight: "20px", paddingBottom: "2px" }}
            alt="Facebook"
          />
        </a>
        &emsp;
        <a
          href="https://www.instagram.com"
          target="_blank"
          style={{ color: "#f4f4f4" }}
        >
          <img
            src={Instagram}
            style={{ maxHeight: "20px", paddingBottom: "2px" }}
            alt="Instagram"
          />
        </a>
      </div>
    </Div>
  );
}

const Div = styled.div`
  color: #f4f4f4;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  width: 100%;
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-word; /* margin: 24px 50px; */
  @media only screen and (min-width: 1600px) {
    font-size: 24px;
    line-height: 46px;
    letter-spacing: 0.92;
  }
`;

export { FooterElement, MobileFooterElement };
