import React from "react";
import "../App.css";
import "antd/dist/antd.css";
import logo from "../assets/logo.png";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

import { Menu, Button, Drawer, Icon } from "antd";

const Image = styled.img`
  height: auto;
  max-width: 100%;
  max-height: 5vh;

  @media only screen and (min-width: 1600px) {
    max-height: 6vh;
  }

  @media only screen and (max-width: 768px) {
    max-height: 4vh;
  }
`;

class LeftMenu extends React.Component {
  render() {
    return (
      <Menu mode="horizontal" id="navBar" onClick={this.props.handleClick}>
        <Menu.Item key="logo" id="navBarLogo">
          <Link to="/">
            <Image src={logo} alt="logo" />
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

class RightMenu extends React.Component {
  render() {
    return (
      <Menu
        mode={this.props.mode}
        id="right_"
        onClick={this.props.handleClick}
        selectedKeys={this.props.active}
      >
        <Menu.Item key="locations">
          <NavLink activeClass="active" to="/locations">
            <span class="navunderline font24">LOCATIONS</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="contact">
          <NavLink activeClass="active" to="/contact">
            <span class="navunderline font24">CONTACT</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    );
  }
}

class NavBar extends React.Component {
  state = {
    current: "none",
    visible: false
  };

  handleClick = e => {
    // this.setState({ current: e.key });
  };

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };
  onClose = () => {
    // console.log("close");
    this.setState({
      visible: false
    });
  };

  // componentDidMount() {
  //   console.log("Menu mounted");
  // }

  render() {
    return (
      <div className="navBarContainer" style={{ display: "flex" }}>
        <LeftMenu handleClick={this.handleClick} />
        <div className="Right_Menu" style={{ marginLeft: "auto" }}>
          <RightMenu
            mode="horizontal"
            handleClick={this.handleClick}
            active={this.state.current}
          />
        </div>
        <div class="barsMenu">
          <Button
            onClick={this.showDrawer}
            className="navBarBtn"
            style={{ alignSelf: "center", boxShadow: "none" }}
          >
            <Icon type="menu" />
          </Button>
        </div>
        <Drawer
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <div onClick={this.onClose}>
            <RightMenu mode="vertical" />
          </div>
        </Drawer>
      </div>
    );
  }
}

export default NavBar;
