import React from "react";
import "antd/dist/antd.css";
import styled from "styled-components";

import { Col, Row } from "antd";

class Banner extends React.Component {
  render() {
    return (
      <StyledRow>
        <Col span={24} style={{ overflowX: "hidden" }}>
          <StyledDiv>
            <StyledImg src={this.props.source} />
            <StyledHeader>{this.props.text}</StyledHeader>
          </StyledDiv>
        </Col>
      </StyledRow>
    );
  }
}

const StyledDiv = styled.div`
  position: relative;
  height: 80px;
  overflow: hidden;
  @media only screen and (min-width: 1600px) {
    height: 156px;
  }
`;

const StyledRow = styled(Row)`
  min-height: 80px;
  max-height: 156px;
  overflow: "hidden";
  @media only screen and (min-width: 1600px) {
    height: 156px;
  }
`;

const StyledImg = styled.img`
  position: absolute;
  width: 100%;
  @media only screen and (max-width: 768px) {
    height: 80px;
    width: unset;
  }
  transform: translate(-50%, 0%);
  @media only screen and (min-width: 1600px) {
    height: 156px;
  }
`;

const StyledHeader = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5em;
  color: #222222;
  width: 100%;
  @media only screen and (min-width: 1600px) {
    font-size: 85px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

export default Banner;
