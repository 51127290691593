import React from "react";
import "antd/dist/antd.css";
import styled from "styled-components";

import { Avatar, Col } from "antd";

class AvatarWithLabel extends React.Component {
  render() {
    return (
      <Column
        lg={{ span: 5, offset: 0 }}
        xl={{ span: 5, offset: 0 }}
        xxl={{ span: 5, offset: 0 }}
        md={{ span: 10, offset: 0 }}
        xs={{ span: 10, offset: 0 }}
        sm={{ span: 10, offset: 0 }}
      >
        <div>
          <StyledAvatar>
            <AvatarImage name="avatar_1" size={120} src={this.props.source} />
          </StyledAvatar>
          <Label for="avatar_1">{this.props.text}</Label>
        </div>
      </Column>
    );
  }
}

const Column = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 0px;
  padding-light: 0px;
`;

const Label = styled.label`
  color: #333132;
  font-size: 16px;
  @media only screen and (min-width: 1600px) {
    font-size: 24px;
    line-height: 46px;
    letter-spacing: 0.92;
  }
`;

const StyledAvatar = styled.div`
  margin-bottom: 10px;
  @media only screen and (min-width: 1600px) {
    margin-bottom: 10px;
    margin-top: 30px;
  }
`;

const AvatarImage = styled(Avatar)`
  @media only screen and (max-width: 576px) {
    height: 55px !important;
    width: 55px !important;
    line-height: 55px !important;
  }
  @media only screen and (max-width: 768px) {
    height: 80px !important;
    width: 80px !important;
    line-height: 80px !important;
  }
  @media only screen and (min-width: 1600px) {
    height: 169px !important;
    width: 169px !important;
    line-height: 169px !important;
  }
`;

export default AvatarWithLabel;
