import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Locations from "./locations";
import Contact from "./contact";
import LocationDetails_1 from "./location_details_1";
import LocationDetails_2 from "./location_details_2";
import LocationDetails_3 from "./location_details_3";
import LocationDetails_4 from "./location_details_4";
import * as serviceWorker from "./serviceWorker";
import { Route, BrowserRouter as Router } from "react-router-dom";

import NavBar from "./components/navBar";
import { FooterElement, MobileFooterElement } from "./components/footer";

import { Layout } from "antd";
const { Header, Content, Footer } = Layout;

const ScrollToTop = () => {
  // console.log("what");
  window.scrollTo(0, 0);
  return null;
};

ReactDOM.render(
  <Router>
    <Route component={ScrollToTop} />
    <div className="App" style={{ marginLeft: "auto", marginRight: "auto" }}>
      <Layout>
        <Header className="App-header">
          <div className="Body">
            <NavBar />
          </div>
        </Header>
        <Content className="Content">
          <div>
            <Route exact path="/" component={App} />
            <Route exact path="/locations" component={Locations} />
            <Route exact path="/locations/1" component={LocationDetails_1} />
            <Route exact path="/locations/2" component={LocationDetails_2} />
            <Route exact path="/locations/3" component={LocationDetails_3} />
            <Route exact path="/locations/4" component={LocationDetails_4} />
            <Route exact path="/contact" component={Contact} />
          </div>
        </Content>
        <Footer
          style={{
            backgroundColor: "#333333",
            height: "20vh",
            maxHeight: "250px",
            display: "flex",
            alignItems: "center",
            whiteSpace: "pre-line"
          }}
        >
          <FooterElement />
          <MobileFooterElement />
        </Footer>
      </Layout>
    </div>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
