import React from "react";
import "antd/dist/antd.css";
import { Col } from "antd";
import styled from "styled-components";

class ContactLocations extends React.Component {
  render() {
    return (
      <StyledCol
        lg={{ span: 12, offset: 0 }}
        xl={{ span: 12, offset: 0 }}
        xxl={{ span: 12, offset: 0 }}
        md={{ span: 22, offset: 1 }}
        sm={{ span: 22, offset: 1 }}
        xs={{ span: 22, offset: 1 }}
      >
        <div style={{ backgroundColor: "#fff5d3", padding: 24 }}>
          <Header>{this.props.header}</Header>
          <div>{this.props.addressLine1}</div>
          <div>{this.props.addressLine2}</div>
          <div>{this.props.city}</div>
          <div>
            <strong>email: </strong>
            <a
              href={this.props.email}
              target="_top"
              style={{ color: "rgba(0, 0, 0, 0.65)" }}
            >
              {this.props.mail}
            </a>
          </div>
          <div>
            <strong>Ph: </strong>
            <a
              href={this.props.phonelnk}
              style={{ color: "rgba(0, 0, 0, 0.65)" }}
            >
              {this.props.phone}
            </a>
          </div>
        </div>
      </StyledCol>
    );
  }
}

const StyledCol = styled(Col)`
  text-align: left;
  /* background-color: #fff5d3; */
  margin-bottom: 24px;
`;

const Header = styled.h1`
  /* padding-top: 15px; */
  @media only screen and (min-width: 1600px) {
    font-size: 28px;
  }
`;

export default ContactLocations;
