import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import LandingImg from "./landing";
import About from "./about";
import Features from "./features";

import styled from "styled-components";

class App extends React.Component {
  componentDidMount() {
    let count = 0;
    var interval = setInterval(function() {
      if (count > 0) {
        clearInterval(interval);
      }
      window.scrollTo(0, 0);
      count++;
    }, 300);
  }
  render() {
    return (
      <div>
        <LandingImg />
        <About />
        <Features />
        <TagLine>REIMAGINE | REINVENT | REINVIGORATE </TagLine>
      </div>
    );
  }
}

const TagLine = styled.section`
  color: #ffcb1f;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0;
  background-color: #fff;
  font-size: 1.8em;
`;

export default App;
