import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import Banner from "./components/banner";
import ContactLocations from "./components/contact_locations";
import BannerImage from "./assets/contact-banner-image.png";
import PhoneImg from "./assets/phone.png";
import MailImg from "./assets/mail.png";

import { Row, Col } from "antd";

class Contact extends React.Component {
  loaded = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    let count = 0;
    var interval = setInterval(function() {
      if (count > 0) {
        clearInterval(interval);
      }
      window.scrollTo(0, 0);
      count++;
    }, 300);
  }

  render() {
    return (
      <div>
        <Banner
          text="flexible spaces. constant support."
          source={BannerImage}
        />
        <section class="section">
          <Row>
            <Col
              lg={{ span: 12, offset: 6 }}
              xl={{ span: 12, offset: 6 }}
              xxl={{ span: 12, offset: 6 }}
              md={{ span: 16, offset: 4 }}
              xs={{ span: 16, offset: 4 }}
              sm={{ span: 16, offset: 4 }}
            >
              Get in touch with us for bulk booking, specific requirements,
              queries, suggestions, to book a tour of our spaces, or just to say
              hello!
            </Col>
          </Row>
          <Row gutter={48} style={{ paddingTop: 10 }}>
            <Col
              lg={{ span: 6, offset: 6 }}
              xl={{ span: 6, offset: 6 }}
              xxl={{ span: 6, offset: 6 }}
              md={{ span: 12, offset: 6 }}
              sm={{ span: 12, offset: 6 }}
              xs={{ span: 12, offset: 6 }}
              style={{ display: "flex", paddingBottom: "10px" }}
            >
              <img
                src={PhoneImg}
                alt="Phone"
                style={{ paddingRight: "5px", height: 25 }}
              />
              <div style={{ alignSelf: "center" }}>
                <a
                  href="tel:+916383912840"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                >
                  +91 6383912840
                </a>
              </div>
            </Col>
            <Col
              lg={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
              xxl={{ span: 6, offset: 0 }}
              md={{ span: 12, offset: 6 }}
              sm={{ span: 12, offset: 6 }}
              xs={{ span: 12, offset: 6 }}
              style={{ display: "flex", paddingBottom: "10px" }}
            >
              <img
                src={MailImg}
                alt="Phone"
                style={{ paddingRight: "5px", height: 25 }}
              />
              <div style={{ alignSelf: "center" }}>
                <a
                  href="mailto:info@energenworks.com?subject=Need%20more%20info%20on%20EnergenWorks.com"
                  target="_top"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                >
                  info@energenworks.com
                </a>
              </div>
            </Col>
          </Row>
        </section>

        <section class="section">
          <Row gutter={24} id="contactLocationsrow">
            <ContactLocations
              header="1101, RESIDENCY ROAD, BENGALURU"
              addressLine1="11th Floor, HMG Ambassador,"
              addressLine2="137, Residency Rd,"
              city="Bengaluru, Karnataka, 560025"
              email="mailto:ambassador@energenworks.com?subject=Need%20more%20info%20on%20bengaluru%20residency%20road,%201101"
              mail="ambassador@energenworks.com"
              phonelnk="tel:+916383912843"
              phone="+91 6383912843"
            />
            <ContactLocations
              header="1202, RESIDENCY ROAD, BENGALURU"
              addressLine1="12th Floor, HMG Ambassador,"
              addressLine2="137, Residency Rd,"
              city="Bengaluru, Karnataka, 560025"
              email="mailto:ambassador@energenworks.com?subject=Need%20more%20info%20on%20bengaluru%20residency%20road,%201202"
              mail="ambassador@energenworks.com"
              phonelnk="tel:+916383912843"
              phone="+91 6383912843"
            />
            <ContactLocations
              header="SECTOR 14, GURUGRAM"
              addressLine1="Unit No. 13B & 14, Palm Court Building,"
              addressLine2="20/4, Mehrauli-Gurugram Rd, Sukhrali Chowk,"
              city="Gurugram 122001"
              email="mailto:palmcourt@energenworks.com ?subject=Need%20more%20info%20on%20gurugram"
              mail="palmcourt@energenworks.com"
              phonelnk="tel:+916383912840"
              phone="+91 6383912840"
            />
            <ContactLocations
              header="ROYAPETTAH, CHENNAI"
              addressLine1="14, Prakash Building,"
              addressLine2="Deivasigamani Road,"
              city="Chennai 600014"
              email="mailto:admin@energenworks.com ?subject=Need%20more%20info%20on%20chennai"
              mail="admin@energenworks.com"
              phonelnk="tel:+918939617114"
              phone="+91 8939617114"
            />
          </Row>
        </section>

        <section className="section">
          <div className="font24">
            Send us a message and we’ll get back to you as soon as we can!
          </div>
        </section>

        <section className="section">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSejmf2ySS2OsWk2Y4JZBTL_QHhsRdEZ_Ljx9yo8FG81Nw2ySA/viewform?embedded=true"
            width="640"
            height="1027"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="contact form"
            style={{ maxWidth: "100%" }}
            onLoad={this.loaded}
          >
            Loading...
          </iframe>
        </section>
      </div>
    );
  }
}

export default Contact;
