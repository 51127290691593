import React from "react";
import "../App.css";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";

import { Card } from "antd";

const { Meta } = Card;

class LocationCard extends React.Component {
  render() {
    let state;
    if (this.props.bordered !== undefined) {
      // console.log(this.props.bordered);
      state = null;
      // console.log(state);
    } else {
      state = "true";
    }
    return (
      <Link to={`locations/${this.props.id}`}>
        <Card
          hoverable
          cover={<img alt="example" src={this.props.source} style={{maxHeight:"340px"}}/>}
          style={{ borderColor: "#ffcb1f" }}
          bordered={state}
          className="locationCard color-414042"
        >
          <Meta
            title={this.props.title}
            style={{ textAlign: `{${this.props.align}}` }}
          />
          <p
            style={{
              paddingTop: "15px",
              margin: 0,
              textAlign: "left"
            }}
          >
            {this.props.addressLine1}
          </p>
          <p
            style={{
              margin: 0,
              textAlign: "left"
            }}
          >
            {this.props.addressLine2}
          </p>
          <p
            style={{
              margin: 0,
              textAlign: "left"
            }}
          >
            {this.props.city}
          </p>
        </Card>
      </Link>
    );
  }
}

export default LocationCard;
