import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import Banner from "./components/banner";
import LocationCard from "./components/card";

import Location1 from "./assets/location1.png";
import Location2 from "./assets/location2.png";
import Location3 from "./assets/location3.png";
import Location4 from "./assets/Roy_5.png";

import TopImage from "./assets/top-image.png";

import { Col, Row } from "antd";

class Locations extends React.Component {
  componentDidMount() {
    let count = 0;
    var interval = setInterval(function() {
      if (count > 0) {
        clearInterval(interval);
      }
      window.scrollTo(0, 0);
      count++;
    }, 300);
  }
  render() {
    return (
      <div>
        <Banner text="a space to call your own" source={TopImage} />
        <div className="section">
          <Row gutter={24} type="flex" style={{ margin: "0" }}>
            <Col
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 12, offset: 0 }}
              xxl={{ span: 12, offset: 0 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              style={{ paddingBottom: "15px" }}
            >
              <LocationCard
                id="1"
                title="1101, RESIDENCY ROAD, BENGALURU"
                addressLine1="11th Floor, HMG Ambassador,"
                addressLine2="137, Residency Rd,"
                city="Bengaluru, Karnataka, 560025"
                align="left"
                source={Location1}
              />
            </Col>

            <Col
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 12, offset: 0 }}
              xxl={{ span: 12, offset: 0 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              style={{ paddingBottom: "15px" }}
            >
              <LocationCard
                className="locationCard"
                id="2"
                title="1202, RESIDENCY ROAD, BENGALURU"
                addressLine1="12th Floor, HMG Ambassador,"
                addressLine2="137, Residency Rd,"
                city="Bengaluru, Karnataka, 560025"
                align="left"
                source={Location2}
              />
            </Col>

            <Col
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 12, offset: 0 }}
              xxl={{ span: 12, offset: 0 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              style={{ paddingBottom: "15px" }}
            >
              <LocationCard
                className="locationCard"
                id="3"
                title="SECTOR 14, GURUGRAM"
                addressLine1="14, Palm Court Building,"
                addressLine2="20/4, Mehrauli-Gurugram Rd, Sukhrali Chowk,"
                city="Gurugram 122001"
                align="left"
                source={Location3}
              />
            </Col>

            <Col
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 12, offset: 0 }}
              xxl={{ span: 12, offset: 0 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              style={{ paddingBottom: "15px" }}
            >
              <LocationCard
                className="locationCard"
                id="4"
                title="ROYAPETTAH, CHENNAI"
                addressLine1="14, Prakash Building,"
                addressLine2="Deivasigamani Road, Royapettah,"
                city="Chennai 600014"
                align="left"
                source={Location4}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Locations;
