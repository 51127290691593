import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import landingImage from "./assets/Home-page.png";
import styled from "styled-components";

import { Carousel } from "antd";

function onChange(a, b, c) {
  console.log(a, b, c);
}

function LandingImg() {
  return (
    <Carousel afterChange={onChange}>
      <Div>
        <CenterDiv class="centered">
          Great minds at work need <br /> Great spaces to work
        </CenterDiv>

        <img
          src={landingImage}
          style={{
            width: "100%",
            backgroundSize: "cover"
          }}
          alt="landingImage"
        />
      </Div>
    </Carousel>
  );
}

const Div = styled.div`
  position: relative;
  text-align: center;
`;

const CenterDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  color: #282727;
  display: inline-table;

  @media only screen and (min-width: 1600px) {
    font-size: 80px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export default LandingImg;
