import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import Banner from "./components/banner";
import AvatarWithLabel from "./components/avatar";
import IndividualWorkspaces from "./assets/individual-workspaces.png";
import Cafeteria from "./assets/cafeteria.png";
import CarParking from "./assets/car-parking.png";
import ConceiergeDesk from "./assets/conceierge-desk.png";
import HighSpeedInternet from "./assets/high-speed-internet.png";
import HouseKeeping from "./assets/housekeeping.png";
import LegalSupport from "./assets/legal-support.png";
import LockersStorage from "./assets/lockers-storage.png";
import MeetingRoom from "./assets/meeting-room.png";
import PhoneBooth from "./assets/phone-booth.png";
import PowerBackup from "./assets/power-backup.png";
import PrinterScanner from "./assets/printer-scanner.png";
import SecuritySurveillance from "./assets/security-surveillance.png";
import VideoConferencing from "./assets/video-conferencing.png";
import TechSupport from "./assets/tech-support.png";
import LocationsTopImage from "./assets/locations-top-image.png";
import PrivateOffice from "./assets/private_office.png";
import CarousalPic1 from "./assets/gurgaon-1.jpg";
import CarousalPic2 from "./assets/gurgaon-2.jpg";
import CarousalPic3 from "./assets/gurgaon-3.jpg";
import CarousalPic4 from "./assets/gurgaon-4.jpg";

import { Col, Row, Button, Table, Divider } from "antd";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

class DemoCarousel extends React.Component {
  render() {
    return (
      <Carousel showStatus={false} showIndicators={false}>
        <div>
          <img
            src={CarousalPic1}
            alt="Gurugram"
            style={{
              objectFit: "cover",
              height: "-webkit-fill-available"
            }}
          />
        </div>
        <div>
          <img
            src={CarousalPic2}
            alt="Gurugram"
            style={{
              objectFit: "cover",
              height: "-webkit-fill-available"
            }}
          />
        </div>
        <div>
          <img
            src={CarousalPic3}
            alt="Gurugram"
            style={{
              objectFit: "cover",
              height: "-webkit-fill-available"
            }}
          />
        </div>
        <div>
          <img
            src={CarousalPic4}
            alt="Gurugram"
            style={{
              objectFit: "cover",
              height: "-webkit-fill-available"
            }}
          />
        </div>
      </Carousel>
    );
  }
}

const columns = [
  {
    title: "TYPE OF SEAT",
    dataIndex: "seatType",
    key: "seatType"
  },
  {
    title: "RATE (excluding tax)",
    dataIndex: "rate",
    className: "rightAlign",
    key: "rate"
  }
];

const data = [
  {
    key: "2",
    seatType: "Hot seat",
    rate: "₹11,250 per seat per month"
  },
  {
    key: "3",
    seatType: "Private Office/Cabin",
    rate: "Price On Request"
  },
  {
    key: "4",
    seatType: "Meeting Room (6-10 seater)",
    rate:
      "₹1,500 per hour\n₹5,000 for 4 hours (+30 minutes)\n₹9,000 for 8 hours (+30 minutes)"
  }
];

class SimpleMap extends React.Component {
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "40vh", width: "100%" }}>
        <iframe
          width="100%"
          height="100%"
          frameborder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed/v1/place?q=Energen%20works%20LLP%2C%20Mehrauli-Gurgaon%20Rd%2C%20Near%20MDI%20Chowk%2C%20Sukhrali%2C%20Chowk%2C%20Gurugram%2C%20Haryana&key=AIzaSyBVPvnSizNWrW47iPvdVzrjUYNSnAAuO0U"
          allowfullscreen
          title="Gurugram location 1"
        />
      </div>
    );
  }
}

class LocationDetails_3 extends React.Component {
  componentDidMount() {
    let count = 0;
    var interval = setInterval(function() {
      if (count > 0) {
        clearInterval(interval);
      }
      window.scrollTo(0, 0);
      count++;
    }, 300);
  }
  render() {
    // const { params } = this.props.match;
    return (
      <div>
        <Banner text="gurugram" source={LocationsTopImage} />

        <section>
          <Row
            gutter={12}
            type="flex"
            className="LocationsDesc"
            style={{ margin: "0" }}
          >
            <Col
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 12, offset: 0 }}
              xxl={{ span: 12, offset: 0 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="section LocationCol"
              style={{ overflow: "hidden" }}
            >
              <DemoCarousel />
            </Col>

            <Col
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 12, offset: 0 }}
              xxl={{ span: 12, offset: 0 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="section LocationCol"
            >
              <div style={{ backgroundColor: "#fff5d3", height: "100%" }}>
                <div
                  style={{
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    textAlign: "left",
                    width: "95%",
                    display: "inline-block"
                    // top: "50%",
                    // left: "50%",
                    // transform: "translate(-50%, -50%)",
                    // position: "absolute"
                  }}
                >
                  <h2 style={{ paddingBottom: "15px" }}>GURUGRAM</h2>
                  <p style={{ paddingBottom: "30px" }}>
                    The gateway to the Capital is also the key to the perfect
                    work experience. In the cluster of those soaring high-rises
                    that scatter across the city of Gurugram, it can be
                    considered a miracle to have a space that is as open as it
                    is central. But what are we if not miracle workers! Situated
                    just a stone’s throw from the city’s major business center,
                    this beautifully designed expanse offers all you and your
                    team need to punch in the hours at a serene pace.
                  </p>
                  <Row>
                    <Col span={8}>
                      <p style={{ margin: "0" }}> No. of Seats: 39</p>
                      <p style={{ margin: "0" }}> Meeting Room: 1 </p>
                      <p style={{ margin: "0" }}> Pantry: 1 </p>
                    </Col>
                    <Col
                      lg={{ span: 10, offset: 4 }}
                      xl={{ span: 10, offset: 4 }}
                      xxl={{ span: 10, offset: 4 }}
                      md={{ span: 8, offset: 8 }}
                      xs={{ span: 10, offset: 7 }}
                      sm={{ span: 10, offset: 7 }}
                      style={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        position: "absolute"
                      }}
                    >
                      <Button id="nxtBtn">MAKE BOOKING</Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <section className="section">
          <div>
            <Row>
              <Col
                lg={{ span: 12, offset: 6 }}
                xl={{ span: 12, offset: 6 }}
                xxl={{ span: 12, offset: 6 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <h2> PLANS & PRICING </h2>
                <div style={{ backgroundColor: "#fff5d3" }}>
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    bordered={false}
                    style={{ whiteSpace: "pre-line" }}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                lg={{ span: 4, offset: 10 }}
                xl={{ span: 4, offset: 10 }}
                xxl={{ span: 4, offset: 10 }}
                md={{ span: 8, offset: 8 }}
                xs={{ span: 10, offset: 7 }}
                sm={{ span: 10, offset: 7 }}
                style={{ paddingTop: "15px" }}
              >
                <Button id="nxtBtn">MAKE BOOKING</Button>
              </Col>
            </Row>

            <Divider
              style={{ width: "90%", margin: "0 auto", minWidth: "90%" }}
            />

            <Row style={{ paddingTop: "15px", paddingBottom: "15px" }}>
              <Col span={12} offset={6}>
                <h2 class="font28"> AMENITITES AVAILABLE AT OUR CENTRE </h2>
              </Col>
            </Row>

            <Row
              gutter={48}
              type="flex"
              style={{
                paddingBottom: "15px",
                justifyContent: "center",
                marginLeft: "0",
                marginRight: "0"
              }}
            >
              <AvatarWithLabel
                source={IndividualWorkspaces}
                text="Individual Workspaces"
              />

              <AvatarWithLabel source={MeetingRoom} text="Meeting Room" />

              <AvatarWithLabel source={PrivateOffice} text="Private Office" />

              <AvatarWithLabel
                source={HighSpeedInternet}
                text="High Speed Internet"
              />

              <AvatarWithLabel source={TechSupport} text="Tech Support" />

              <AvatarWithLabel
                source={PrinterScanner}
                text="Printer & Scanner"
              />

              <AvatarWithLabel source={PhoneBooth} text="Phone Booth" />

              <AvatarWithLabel source={PowerBackup} text="Power Backup" />

              <AvatarWithLabel source={LockersStorage} text="Locker Storage" />

              <AvatarWithLabel source={HouseKeeping} text="Housekeeping" />

              <AvatarWithLabel source={Cafeteria} text="Cafeteria" />

              <AvatarWithLabel
                source={VideoConferencing}
                text="Video Conferencing"
              />

              <AvatarWithLabel source={ConceiergeDesk} text="Conceirge Desk" />

              <AvatarWithLabel source={CarParking} text="Car Parking" />

              <AvatarWithLabel
                source={SecuritySurveillance}
                text="Security & surveillance"
              />

              <Col
                lg={{ span: 5, offset: 0 }}
                xl={{ span: 5, offset: 0 }}
                xxl={{ span: 5, offset: 0 }}
                md={{ span: 10, offset: 0 }}
                xs={{ span: 10, offset: 0 }}
                sm={{ span: 10, offset: 0 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                <span id="ljj" />
              </Col>
              <Col
                lg={{ span: 5, offset: 0 }}
                xl={{ span: 5, offset: 0 }}
                xxl={{ span: 5, offset: 0 }}
                md={{ span: 10, offset: 0 }}
                xs={{ span: 10, offset: 0 }}
                sm={{ span: 10, offset: 0 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                <span id="lkj" />
              </Col>
              <Col
                lg={{ span: 5, offset: 0 }}
                xl={{ span: 5, offset: 0 }}
                xxl={{ span: 5, offset: 0 }}
                md={{ span: 10, offset: 0 }}
                xs={{ span: 10, offset: 0 }}
                sm={{ span: 10, offset: 0 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                <span id="llj" />
              </Col>
            </Row>
          </div>
        </section>

        <section className="section">
          <Row>
            <Col
              span={24}
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              <SimpleMap />
            </Col>
          </Row>
        </section>
      </div>
    );
  }
}

export default LocationDetails_3;
