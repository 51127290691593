import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import Banner from "./components/banner";
import AvatarWithLabel from "./components/avatar";
import IndividualWorkspaces from "./assets/individual-workspaces.png";
import Cafeteria from "./assets/cafeteria.png";
import CarParking from "./assets/car-parking.png";
import ConceiergeDesk from "./assets/conceierge-desk.png";
import HighSpeedInternet from "./assets/high-speed-internet.png";
import HouseKeeping from "./assets/housekeeping.png";
import LegalSupport from "./assets/legal-support.png";
import LockersStorage from "./assets/lockers-storage.png";
import MeetingRoom from "./assets/meeting-room.png";
import OpenTerrace from "./assets/open-terrace.png";
import PhoneBooth from "./assets/phone-booth.png";
import PowerBackup from "./assets/power-backup.png";
import PrinterScanner from "./assets/printer-scanner.png";
import SecuritySurveillance from "./assets/security-surveillance.png";
import VideoConferencing from "./assets/video-conferencing.png";
import TechSupport from "./assets/tech-support.png";
import LocationsTopImage from "./assets/locations-top-image.png";

import CarousalPic1 from "./assets/1101-1.jpg";
import CarousalPic2 from "./assets/1101-2.jpg";
import CarousalPic3 from "./assets/1101-3.jpg";
import CarousalPic4 from "./assets/1101-4.jpg";

import { Col, Row, Button, Table, Divider } from "antd";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

class DemoCarousel extends React.Component {
  render() {
    return (
      <Carousel showStatus={false} showIndicators={false}>
        <div>
          <img
            src={CarousalPic1}
            alt="Bangalore 1101"
            style={{ objectFit: "cover", height: "-webkit-fill-available" }}
          />
        </div>
        <div>
          <img
            src={CarousalPic2}
            alt="Bangalore 1101"
            style={{ objectFit: "cover", height: "-webkit-fill-available" }}
          />
        </div>
        <div>
          <img
            src={CarousalPic3}
            alt="Bangalore 1101"
            style={{ objectFit: "cover", height: "-webkit-fill-available" }}
          />
        </div>
        <div>
          <img
            src={CarousalPic4}
            alt="Bangalore 1101"
            style={{ objectFit: "cover", height: "-webkit-fill-available" }}
          />
        </div>
      </Carousel>
    );
  }
}

const columns = [
  {
    title: "TYPE OF SEAT",
    dataIndex: "seatType",
    key: "seatType"
  },
  {
    title: "RATE (excluding tax)",
    dataIndex: "rate",
    className: "rightAlign",
    key: "rate"
  }
];

const data = [
  {
    key: "1",
    seatType: "Hotseat",
    rate: "₹11,750 per seat per month"
  },
  {
    key: "2",
    seatType: "Private Office/Cabin",
    rate: "Price On Request"
  },
  {
    key: "3",
    seatType: "Meeting Room (6-10 seater)",
    rate:
      "₹2,500 per hour\n₹9,000 for 4 hours (+30 minutes)\n₹15,000 for 8 hours (+30 minutes)"
  }
];

class SimpleMap extends React.Component {
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "40vh", width: "100%" }}>
        <iframe
          width="100%"
          height="100%"
          frameborder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed/v1/place?q=Energen%20works%20LLP%2C%20Residency%20Rd%2C%20Bengaluru&key=AIzaSyBVPvnSizNWrW47iPvdVzrjUYNSnAAuO0U"
          allowfullscreen
          title="Bangalore location 1"
        />
      </div>
    );
  }
}

class LocationDetails_1 extends React.Component {
  componentDidMount() {
    let count = 0;
    var interval = setInterval(function() {
      if (count > 0) {
        clearInterval(interval);
      }
      window.scrollTo(0, 0);
      count++;
    }, 300);

    // const google = window.google;
    //
    // var map;
    // function initMap() {
    //   map = new google.maps.Map(document.getElementById("map"), {
    //     center: { lat: 13.01527, lng: 77.76178 },
    //     zoom: 8
    //   });
    // }
    //
    // initMap();
  }
  render() {
    // const { params } = this.props.match;
    return (
      <div>
        <Banner text="residency road, 1101" source={LocationsTopImage} />

        <section>
          <Row
            gutter={12}
            type="flex"
            className="LocationsDesc"
            style={{ margin: "0" }}
          >
            <Col
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 12, offset: 0 }}
              xxl={{ span: 12, offset: 0 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="section LocationCol"
              style={{ overflow: "hidden" }}
            >
              <DemoCarousel />
            </Col>

            <Col
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 12, offset: 0 }}
              xxl={{ span: 12, offset: 0 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="section LocationCol"
              style={{ margin: 0 }}
            >
              <div style={{ backgroundColor: "#fff5d3", height: "100%" }}>
                <div
                  style={{
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    textAlign: "left",
                    width: "95%",
                    display: "inline-block"
                    // top: "50%",
                    // left: "50%",
                    // transform: "translate(-50%, -50%)",
                    // position: "absolute"
                  }}
                >
                  <h2 style={{ paddingBottom: "15px" }}>
                    BENGALURU RESIDENCY ROAD 1101
                  </h2>
                  <p style={{ paddingBottom: "30px" }}>
                    Go big or go home! That is why our formidable workspace in
                    Bengaluru is situated in an enviable location on Residency
                    Road. It is also why our open workspaces comes only in L and
                    XL! Situated high above the bustle of the city, 1101 affords
                    you the mindspace to focus on the task at hand while the
                    terrace garden nook allows you to take that much needed
                    breather while you soak in the spectacular view of this
                    urban jungle. Did we mention the private office?
                  </p>
                  <Row>
                    <Col span={8}>
                      <p style={{ margin: "0" }}> No. of Seats: 35</p>
                      <p style={{ margin: "0" }}> Meeting Room: 1 </p>
                      <p style={{ margin: "0" }}> Pantry and Open Terrace </p>
                    </Col>
                    <Col
                      lg={{ span: 10, offset: 4 }}
                      xl={{ span: 10, offset: 4 }}
                      xxl={{ span: 10, offset: 4 }}
                      md={{ span: 8, offset: 4 }}
                      xs={{ span: 10, offset: 4 }}
                      sm={{ span: 10, offset: 4 }}
                      style={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        position: "absolute"
                      }}
                    >
                      <Button id="nxtBtn">MAKE BOOKING</Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <section className="section">
          <div>
            <Row>
              <Col
                lg={{ span: 12, offset: 6 }}
                xl={{ span: 12, offset: 6 }}
                xxl={{ span: 12, offset: 6 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <h2> PLANS & PRICING </h2>
                <div style={{ backgroundColor: "#fff5d3" }}>
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    bordered={false}
                    style={{ whiteSpace: "pre-line" }}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                lg={{ span: 4, offset: 10 }}
                xl={{ span: 4, offset: 10 }}
                xxl={{ span: 4, offset: 10 }}
                md={{ span: 8, offset: 8 }}
                xs={{ span: 10, offset: 7 }}
                sm={{ span: 10, offset: 7 }}
                style={{ paddingTop: "15px" }}
              >
                <Button id="nxtBtn">MAKE BOOKING</Button>
              </Col>
            </Row>

            <Divider
              style={{ width: "90%", margin: "0 auto", minWidth: "90%" }}
            />

            <Row style={{ paddingTop: "15px", paddingBottom: "15px" }}>
              <Col span={12} offset={6}>
                <h2 class="font28"> AMENITITES AVAILABLE AT OUR CENTRE </h2>
              </Col>
            </Row>

            <Row
              gutter={48}
              type="flex"
              style={{
                paddingBottom: "15px",
                justifyContent: "center",
                marginLeft: "0",
                marginRight: "0"
              }}
            >
              <AvatarWithLabel
                source={IndividualWorkspaces}
                text="Individual Workspaces"
              />

              <AvatarWithLabel source={MeetingRoom} text="Meeting Room" />

              <AvatarWithLabel
                source={HighSpeedInternet}
                text="High Speed Internet"
              />

              <AvatarWithLabel source={TechSupport} text="Tech Support" />

              <AvatarWithLabel
                source={PrinterScanner}
                text="Printer & Scanner"
              />

              <AvatarWithLabel source={PhoneBooth} text="Phone Booth" />

              <AvatarWithLabel source={PowerBackup} text="Power Backup" />

              <AvatarWithLabel source={LockersStorage} text="Locker Storage" />

              <AvatarWithLabel source={HouseKeeping} text="Housekeeping" />

              <AvatarWithLabel source={Cafeteria} text="Cafeteria" />

              <AvatarWithLabel source={OpenTerrace} text="OpenTerrace" />

              <AvatarWithLabel
                source={VideoConferencing}
                text="Video Conferencing"
              />

              <AvatarWithLabel source={ConceiergeDesk} text="Conceirge Desk" />

              <AvatarWithLabel source={CarParking} text="Car Parking" />

              <AvatarWithLabel
                source={SecuritySurveillance}
                text="Security & surveillance"
              />

              <Col
                lg={{ span: 5, offset: 0 }}
                xl={{ span: 5, offset: 0 }}
                xxl={{ span: 5, offset: 0 }}
                md={{ span: 10, offset: 0 }}
                xs={{ span: 10, offset: 0 }}
                sm={{ span: 10, offset: 0 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                <span id="ljj" />
              </Col>
              <Col
                lg={{ span: 5, offset: 0 }}
                xl={{ span: 5, offset: 0 }}
                xxl={{ span: 5, offset: 0 }}
                md={{ span: 10, offset: 0 }}
                xs={{ span: 10, offset: 0 }}
                sm={{ span: 10, offset: 0 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                <span id="lkj" />
              </Col>
              <Col
                lg={{ span: 5, offset: 0 }}
                xl={{ span: 5, offset: 0 }}
                xxl={{ span: 5, offset: 0 }}
                md={{ span: 10, offset: 0 }}
                xs={{ span: 10, offset: 0 }}
                sm={{ span: 10, offset: 0 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                <span id="llj" />
              </Col>
            </Row>
          </div>
        </section>

        <section className="section">
          <Row>
            <Col
              span={24}
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              <SimpleMap />
            </Col>
          </Row>
        </section>
      </div>
    );
  }
}

export default LocationDetails_1;
