import React from "react";
import "./App.css";
import "antd/dist/antd.css";

function About() {
  return (
    <section className="section" id="about">
      <div class="center-container">
        <h1
          class="header"
          style={{ color: "#FFCB1F", fontWeight: "bold", textAlign: "justify" }}
        >
          THE WORLD MOVES AT A FRENZIED PACE. ALL AROUND US FALLS A TORRENTIAL
          DOWNPOUR OF WORK. AMIDST THIS CHAOS WE ALL NEED THAT OASIS TO ESCAPE
          TO AND FOCUS ON THE TASK AT HAND. ENERGEN WORKS IS THAT OASIS.
        </h1>
        <div class="center-container" style={{ width: "95%" }}>
          <p style={{ textAlign: "left" }}>
            Our expertly designed spaces and curated offerings (round the clock
            tech support anyone?) all come together to allow you to be at your
            productive and creative best. We believe that efficient spaces lead
            to efficient work.
          </p>
          <p style={{ textAlign: "left" }}>
            All that stands between you and an entirely hassle free experience
            is just a click away. We've cut out the middleman and fluff to give
            you a truly seamless experience, right from the get-go. So get
            started - book a slot, pay online, and voila - your oasis awaits!
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
